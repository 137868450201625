<template>
  <div class="elv-table-cell-auxiliaryCode-main">
    <template v-if="auxiliaryValueList">
      <div v-for="item in auxiliaryValueList" :key="item.auxiliaryCodeId" class="elv-table-cell-auxiliaryCode-item">
        {{ item.auxiliaryType?.name }}: {{ getAuxiliaryValue(item) }}
      </div>
    </template>
    <div
      v-else-if="
        props.params?.data?.auxiliaryType?.name &&
        (props.params?.data?.counterparty?.name ||
          props.params?.data?.entityAccount?.name ||
          props.params?.data?.auxiliaryItem?.value)
      "
      class="elv-table-cell-auxiliaryCode-item"
    >
      {{ props.params?.data?.auxiliaryType?.name }}:
      {{
        props.params?.data?.entityAccount?.name ??
        props.params?.data?.counterparty?.name ??
        props.params?.data?.auxiliaryItem?.value
      }}
    </div>
  </div>
</template>
<script lang="ts" setup>
const props = defineProps({
  params: {
    type: Object,
    default: () => {
      return {}
    }
  }
})

const auxiliaryValueList = computed(() => {
  return props.params?.data?.auxiliaryValueList?.filter(
    (item: any) =>
      item.auxiliaryType?.name && (item?.entityAccount?.name || item?.counterparty?.name || item.auxiliaryItem?.value)
  )
})

const getAuxiliaryValue = (item: any) => {
  if (item.auxiliaryType.type === 'ENTITY_ACCOUNT') {
    return item?.entityAccount?.name
  }

  if (item.auxiliaryType.type === 'COUNTERPARTY') {
    return item?.counterparty?.name
  }
  if (item.auxiliaryType.type === 'ITEM') {
    return item.auxiliaryItem?.value
  }

  return null
}
</script>
<style lang="scss">
.elv-table-cell-auxiliaryCode-main {
  display: flex;
  align-items: center;

  .elv-table-cell-auxiliaryCode-item {
    display: flex;
    padding: 4px 12px;
    height: 23px;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    color: #0e0f11;
    font-family: 'Plus Jakarta Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-right: 4px;
    background-color: #f9fafb;
    border-radius: 12px;
    border: 1px solid #edf0f3;
    white-space: nowrap;

    &:last-of-type {
      margin-right: 0;
    }
  }
}
</style>
